import { Spline } from "@splinetool/react-spline";
import Vetri from "./images/pfp.png";
import BG from './images/BGImage.jpg'; // Desktop background image
import BGMobile from './images/BGImageMobile.webp'; // Mobile background image
import banner from "./images/banner.png";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Experience, Projects, SocialLinks, Education } from "./data";
import {  IoMenu } from "react-icons/io5";
import { useState, useEffect, useRef  } from "react";
import { AnimatePresence, motion } from "framer-motion";
import ReactSpinners from "react-spinners/ClipLoader";

import { Application } from '@splinetool/runtime';
import Astro from "./images/astro_share_1.png";
import anime from 'animejs/lib/anime.es.js';







function App() {

  
  const [backgroundImage, setBackgroundImage] = useState('');
  const [backgroundSize, setBackgroundSize] = useState('cover'); // Default size for desktop
  const [backgroundAtt, setBackgroundAtt] = useState('fixed');
  

  useEffect(() => {
    // Function to check if the screen width is less than 768px (indicating a mobile device)
    const checkScreenWidth = () => {
      if (window.innerWidth < 768) {
        setBackgroundImage(BGMobile); // Set the mobile background image
        setBackgroundSize('100% 100%'); // Change background size to '100% 100%' for mobile
        setBackgroundAtt('fixed');
      } else {
        setBackgroundImage(BG); // Set the desktop background image
        setBackgroundSize('cover'); // Change background size to 'cover' for desktop
      }
    };


    // Add event listener to check if the screen size changes
    window.addEventListener('resize', checkScreenWidth);

    // Initial check on component mount
    checkScreenWidth();

    // Remove event listener on component unmount
    return () => window.removeEventListener('resize', checkScreenWidth);
  }, []);
  
    // ...

    const [isLoading, setIsLoading] = useState(true);
    const myElement = useRef(null);

    useEffect(() => {
      // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    

    if (isLoading && myElement.current) {
      return <ReactSpinners type="ThreeDots" color="#2BAD60" height="100" width="100" />;
    }
  }, [isLoading]);
  
    // ...
  const [isActive, setIsActive] = useState(false);


<div>
   
  </div>
  
  return (
    
    
    
    <AnimatePresence initial={false}>
      <div 
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: backgroundSize, // Use the backgroundSize state to adjust the size
        backgroundAttachment: 'fixed', // Set the background image as fixed
        top: 0,
        left: 0,
        overflow: 'hidden',
      }}
      className={`flex w-screen min-h-screen flex-col items-center justify-center relative bg-primary pb-20 ${
        window.innerWidth < 768 ? "mobile-background" : "desktop-background"
      }`}
      >
      

        <nav className="w-full px-6 z-50  fixed inset-x-0 top-2 flex justify-center items-center ">
          <div className=" w-full md:w-880 bg-navBar p-4 rounded-2xl flex items-center navbar transparent navbar-inverse" style={{ backgroundColor: 'rgba(21,24,31, 0.8)' }}>
         
            <p className="text-lg  text-slate-100 font-medium">LA Chieng</p>

            <div className="hidden md:flex items-center gap-6 ml-6 flex-1">
              <a
                href="#home"
                
                className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
              >
                Home
              </a>
              <a
                href="#about"
                className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
              >
                About
              </a>
              <a
                href="#exp"
                className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
              >
                Experiences
              </a>
              <a
                href="#edu"
                className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
              >
                Education
              </a>
              <a
                href="#projects"
                className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
              >
                Projects
              </a>
              <a
                href="#contacts"
                className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
              >
                Contact
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=60122260821&text=YO"
                className="ml-auto text-center  text-base text-textBase font-medium hover:text-slate-100 cursor-pointer border border-textBase px-2 py-1 rounded-xl hover:border-gray-100 duration-100 ease-in"
              >
                Surprise Me
              </a>
              
            </div>

            <motion.div
              whileTap={{ scale: 0.6 }}
              className="block md:hidden  ml-auto cursor-pointer"
              onClick={() => setIsActive(!isActive)}
            >
              <IoMenu className="text-2xl text-textBase " />
            </motion.div>
            {isActive && (
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1.1 }}
                exit={{ opacity: 0, scale: 0.5 }}
                transition={{ delay: "0.1s", type: "spring" }}
                className="p-4 w-275 bg-navBar rounded-lg fixed top-24 right-16 flex flex-col items-center justify-evenly gap-6"
              >
                <a
                  href="#home"
                  className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
                  onClick={() => setIsActive(false)}
                >
                  Home
                </a>
                <a
                  href="#about"
                  className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
                  onClick={() => setIsActive(false)}
                >
                  About
                </a>
                <a
                  href="#exp"
                  className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
                  onClick={() => setIsActive(false)}
                >
                  Experiences
                </a>
                <a
                  href="#edu"
                  className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
                  onClick={() => setIsActive(false)}
                >
                  Education
                </a>
                <a
                  href="#projects"
                  className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
                  onClick={() => setIsActive(false)}
                >
                  Projects
                </a>
                <a
                  href="#contacts"
                  className="text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
                  onClick={() => setIsActive(false)}
                >
                  Contact
                </a>
                <a
                  href="https://api.whatsapp.com/send/?phone=60122260821&text=YO"
                  className="text-base text-center  text-textBase font-medium hover:text-slate-100 cursor-pointer border border-textBase px-2 py-1 rounded-xl hover:border-gray-100 duration-100 ease-in"
                  onClick={() => setIsActive(false)}
                >
                    Surprise Me
                </a>
              </motion.div>
            )}
          </div>
        </nav>

        <div className="relative" id="home" style={{
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}>
  <img src={banner} alt="banner image" className="h-80 md:h-96 lg:h-100 w-full object-cover" />
  
  

</div>  

        

        <main className="w-[90%] mt-4">
          <section
            className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 my-24"
            id="about"
          >
            <div className="w-full h-420 flex items-center justify-center">
              <div className="w-340 h-340 relative bg-orange-200 rounded-full overflow-hidden">
                <img
                  src={Vetri}
                  alt="lachieng"
                  className="w-full h-full absolute  object-cover rounded-full drop-shadow-2x"
                                />
              </div>
            </div>
              
            <div className="w-full h-full flex flex-col items-center  text-center">
              <p className="w-full  p-2 flex items-center justify-center text-lg text-textBase rounded-2xl text-white text-left" style={{
    backgroundColor: 'rgba(21,24,31, 0.7)',
    zIndex: 1,
  }}>
              Revenue Assurance and Business Analytics Leader with 10+ years of experience in the PayTV industry. Expertise in revenue growth, leakage detection, and data-driven decision-making using SQL and Data visualization.  
              
              </p>
              <p className="w-full  p-2 flex items-center text-lg text-textBase rounded-2xl text-white text-left" style={{ backgroundColor: 'rgba(21,24,31, 0.7)',zIndex: 1 }}>
              Consistently drives business success through innovative solutions. Passionate about technology and exploring emerging trends such as blockchain, Non Fungible Token(NFT), cryptocurrency, Fintech and Web3.

              </p>
              <p className="w-full  p-2 flex items-center text-lg text-textBase rounded-2xl text-white text-left" style={{ backgroundColor: 'rgba(21,24,31, 0.7)',zIndex: 1 }}>
              Skilled in digital marketing, with experience in website building using cloud computing platform, Open Source, Google Analytics, Search Console, and AdSense.

              </p>
              <p className="w-full  p-2 flex items-center text-lg text-textBase rounded-2xl text-white text-left" style={{ backgroundColor: 'rgba(34,139,34, 0.7)',zIndex: 1 }}>
              I have a proven track record of finding and fixing revenue leakage, preventing disruptions, ensuring regulatory compliance, and providing data insights and dashboards to improve financial planning and operational management. Fueled by my passion for learning and exploring new opportunities, I am excited to take on the challenges that lie ahead.

              </p>
              {/* <p className="w-full  p-2 flex items-center text-lg text-textBase rounded-2xl text-white text-left" style={{ backgroundColor: 'rgba(21,24,31, 0.7)',zIndex: 1 }}>
              
I am also highly passionate about exploring emerging technologies such as blockchain technology, trading cryptocurrency, NFTs and AI related(OpenAI and Midjourney). Moreover, I have utilized Google tools like Google Analytics, Google Search Console, and Google AdSense to build websites, granting me invaluable insights into digital marketing and e-commerce. 


              </p> */}

<p className="w-full  p-2 flex items-center text-lg text-textBase rounded-2xl text-white text-left" style={{ backgroundColor: 'rgba(69, 75, 27, 0.7)',zIndex: 1 }}>
  Hard Skills:

  <br />
  Unix<br />
SQL for Data Analysis<br />
Visual Studio<br />
Cloud Computing<br />
Web Hosting<br />
ReactJS, ThreeJS, WhatsappJS<br />
Python<br />
Google Analytics, Search Console, Adsense, Cloudflare<br />
Microsoft Office (Ms. Word, Ms. Excel, Ms. PowerPoint)<br />
Digital Marketing Strategies (Including SEO and Social Media Marketing)<br />
AI Tools: ChatGPT/API, Midjourney<br />
Canva<br />
PowerBI<br />
Revenue Assurance<br />
Google Data Studio<br />
Blockchain Technology and Smart Contract Development<br />
Deep understanding on CRM (Customer Relationship Management) system<br />
<br />

  Soft Skills:<br />
  Self-learner with a proactive approach <br />
  Strong Analytical and Problem-Solving Skills<br />
  Excellent Communication Skills<br />
  Effective Teamwork Skills<br />
  Customer-centric perspective<br />
  Results-oriented<br />
  Data-Driven<br />
  ROI analysis skills<br />
  Problem-solving skills<br />
</p>
    
            </div>
          </section>
<div className="w-full h-420 flex flex-col items-center justify-center "><p className="text-lg text-yellow-200 text-center text-6xl cursor-pointer p-4 rounded-2xl" id="exp" style={{
    backgroundColor: 'rgba(250,140,0, 0.7)',
    zIndex: 1,
}}>My Experiences</p></div>
          <section className="w-full flex items-center justify-center" >
            <VerticalTimeline>
              {Experience &&
                Experience.map((n) => (
                  <VerticalTimelineElement
                    key={n.id}
                    className="vertical-timeline-element--work"
                    contentStyle={{
                      background: "rgb(21, 24, 31, 0.7)",
                      color: "#FFFFFF",
                    }}
                    contentArrowStyle={{
                      borderRight: "7px solid  rgb(21, 24, 31)",
                    }}
                    date={n.date}
                    iconStyle={{ background: "rgb(21, 24, 31)", color: "#888" }}
                    icon={n.iconsSrc}
                  >
                    <h3 className="vertical-timeline-element-title">
                      {n.title}
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                      {n.location}
                    </h4>
                    <p class="desc">{n.description}</p>
                    <p class="achievement">{n.achievement}</p>
                    <p class="desc">{n.description2}</p>
                    <p class="achievement">{n.achievement2}</p>
                    <p class="desc">{n.description3}</p>
                    <p class="achievement">{n.achievement3}</p>
                    <p class="desc">{n.description4}</p>
                    <p class="achievement">{n.achievement4}</p>
                    <p class="desc">{n.description5}</p>
                    <p class="achievement">{n.achievement5}</p>
                  </VerticalTimelineElement>
                ))}
            </VerticalTimeline>
          </section>

          <div className="w-full h-420 flex flex-col items-center justify-center "><p className="text-lg text-yellow-200 text-center text-6xl cursor-pointer p-4 rounded-2xl" id="edu" style={{
    backgroundColor: 'rgba(250,140,0, 0.7)',
    zIndex: 1,
}}>My Education</p></div>
         
          <section className="w-full flex items-center justify-center">
            <VerticalTimeline>
              {Education &&
                Education.map((n) => (
                  <VerticalTimelineElement
                    key={n.id}
                    className="vertical-timeline-element--work"
                    contentStyle={{
                      background: "rgb(21, 24, 31, 0.7)",
                      color: "#FFFFFF",
                    }}
                    contentArrowStyle={{
                      borderRight: "7px solid  rgb(21, 24, 31)",
                    }}
                    date={n.date}
                    iconStyle={{ background: "rgb(21, 24, 31)", color: "#888" }}
                    icon={n.iconsSrc}
                  >
                    <h3 className="vertical-timeline-element-title">
                      {n.title}
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                      {n.location}
                    </h4>
                    <p>{n.description}</p>
                    
                  </VerticalTimelineElement>
                ))}
            </VerticalTimeline>
          </section>

          <div className="w-full h-420 flex flex-col items-center justify-center "><p className="text-lg text-yellow-200 text-center text-6xl cursor-pointer p-4 rounded-2xl" id='projects' style={{
    backgroundColor: 'rgba(250,140,0, 0.7)',
    zIndex: 1,
}}>My Projects</p></div>
          <section
            className="flex flex-wrap items-center justify-evenly my-24 gap-4"
            id="projects"
          >
            {Projects &&
              Projects.map((n, i) => (
                <motion.div
                  key={n.id}
                  className="border border-zinc-800 rounded-md p-2 min-w-[275px] md:max-w-[275px] hover:border-zinc-600 duration-100 ease-in-out"
                >
                  <p className="text-lg  text-white font-medium lowercase" style={{
    backgroundColor: 'rgba(250,140,0, 0.7)',
    zIndex: 1,
}}>
                    {n.name.length > 25 ? `${n.name.slice(0, 25)}...` : n.name}
                  </p>

                  <img
                    src={n.imageSrc}
                    className="w-full h-full object-cover rounded-md my-4"
                    alt=""
                  />

                  <div className="flex flex-1 items-center justify-between">
                    <p className="text-lg text-white" style={{
    backgroundColor: 'rgba(250,140,0, 0.7)',
    zIndex: 1,
}}>
                      Technologies
                      <span className="block text-sm text-white" style={{
    backgroundColor: 'rgba(250,140,0, 0.7)',
    zIndex: 1,
}}>
                        {n.techs}
                      </span>
                    </p>
                   
                  </div>
                </motion.div>
              ))}
          </section>

          <section
            id="contacts"
            className="flex flex-col items-center justify-evenly w-full my-24"
          >
            <p className="text-lg text-yellow-200 text-center text-3xl cursor-pointer p-4 rounded-2xl" id='contacts' style={{
    backgroundColor: 'rgba(250,140,0, 0.7)',
    zIndex: 1,
}}>Follow me on</p>
            <div className="flex items-center justify-evenly w-full my-4 flex-wrap gap-4">
              {SocialLinks &&
                SocialLinks.map((n) => (
                  <motion.a
                    whileTap={{ scale: 0.8 }}
                    href={n.link}
                    key={n.id}
                    className="w-full md:w-auto px-3 md:px-8 py-5 border border-zinc-800 rounded-2xl hover:border-zinc-600 duration-100 ease-in-out cursor-pointer flex items-center justify-center gap-3"
                  >
                    {n.iconSrc}
                    <p className="text-lg text-white  cursor-pointer p-4 rounded-2xl" style={{
    backgroundColor: 'rgba(250,140,0, 0.7)',
    zIndex: 1,
}}>{n.name}</p>
                  </motion.a>
                ))}
            </div>
          </section>
        </main>
      </div>
    </AnimatePresence>
  
  );
}



export default App;


