import React from "react";
import {hydrate, render }  from "react-dom";
import ReactDOM   from "react-dom";
import "./index.css";
import App from "./App";
// import { useReactRouterBreadcrumbs } from 'use-react-router-breadcrumbs';




ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);


const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}



// return (
//   <div>
//     <ul>
//       {breadcrumbs.map((breadcrumb, index) => (
//         <li key={index}>
//           {breadcrumb.label}
//         </li>
//       ))}
//     </ul>
//   </div>
// );

