import {
  IoBriefcaseOutline,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoLogoWhatsapp,IoBusinessOutline,IoBuild,
} from "react-icons/io5";
import { FaXTwitter } from "react-icons/fa6";
import { AiOutlineMail } from "react-icons/ai";
import img1 from "./images/nfteaclub8.png";
import img2 from "./images/durianz2.png";



export const Experience = [
  {
    id: 1,
    date: "Jul 2021 - present",
    iconsSrc: <IoBuild />,
    title: "Self Employed",
    location: "Selangor, Malaysia",
    description:
      "Visionary Leader with a passion for emerging technologies, actively exploring blockchain, cryptocurrency, and NFT trading. Proven ability to develop and launch successful NFT projects on the Ethereum mainnet. Deep understanding of the NFT market. Expertise in website construction, Google Analytics, Google Search Console, and Google AdSense, specifically in building personal portfolio and business landing pages. Seeking new opportunities to leverage my skills and experience to create value and transform businesses.", 
      achievement:"This has allowed me to stay ahead of the curve and develop the skills and knowledge necessary to succeed in this rapidly evolving field. This has also given me the ability to build and manage websites, track and analyze website traffic, optimize websites for search engines and content monetization."
  },
  {
    id: 2,
    date: "Dec 2012(Senior Associate) - Jul 2021(Senior Assistant Vice President)",
    iconsSrc: <IoBusinessOutline />,
    title: "PayTV company(Astro)- Senior Assistant Vice President, Revenue Assurance",
    location: "Kuala Lumpur, Malaysia",
    description:
      "Revenue Leakage Detection: Revenue Assurance Leader with 10+ years of experience in the PayTV industry. Expertise in designing and refining methods for spotting revenue leakage across diverse product portfolios, including Pay TV, Commerce, Over the Top, and e-Wallet. Proven ability to develop and implement proactive strategies informed by business feedback, Audit, and Risk committee insights.",
    achievement: "Achievement: Reduced revenue leakage, saving the company millions of dollars.",
    description2:
      "System Readiness Assurance: System Readiness Assurance Leader with experience in overseeing critical reviews of system flows, UAT test cases, and data migration checks. Proven ability to mitigate disruptions and uphold service excellence.",
    achievement2: "Achievement: Ensuring seamless business continuity for segments like Pay TV and Commerce. Avoiding costly delays and service outages by identifying and addressing potential problems early on.",
    description3:
      "Audit Collaboration: Audit Collaboration Expert with experience in collaborating closely with external/internal auditors and business units to validate findings and uphold transparency, accountability, and regulatory compliance.",
    achievement3: "Achievement: This ensured that the company was in good standing with regulators and that its financial reporting was accurate.",
    description4:
      "Data-Driven Decision Making: Data-Driven Decision Making Leader with experience in crunching millions of data points from multiple sources to provide data insights, process analytics, support finance report benchmarking, data reconciliation, and strategic decision-making.",
    achievement4: "Achievement: This enhanced financial planning and performance evaluation by providing decision-makers with the information they need to make informed decisions.",
    description5:
      "Monthly Dashboard Development: Dashboard Development Expert with experience in creating and presenting comprehensive monthly dashboards to business heads, offering vital insights into inventory, subscription revenue, debtors aging, campaign efficacy/sustainability, and exceptions.",
    achievement5: "Achievement: Instrumental for operational management and continuous improvement by providing stakeholders with a clear understanding of the company's performance."
  },
  {
    id: 3,
    date: "May 2011 - Dec 2012",
    iconsSrc: <IoBusinessOutline />,
    title: "C.R.M. Solution — Senior Subject Matter Expert",
    location: "Kuala Lumpur, Malaysia",
    description:
      "Business Support and Change Management Expert with experience in providing extensive support to business teams on system functionality inquiries and change request requirements, supporting a ClarifyCRM system for Call Center in the Telco environment, resolving any issues that have arisen and monitoring systems for issue prevention, and successfully performing implementations for change requests raised by the project team with minimal impact. Focused on providing quality service and effective solutions for any challenges that arise.",
      achievement: "Achievement: Successfully supported a ClarifyCRM system for Call Center in the Telco environment, resolving any issues that have arisen and monitoring systems for issue prevention, ensuring a high level of customer service.",
  },
  {
    id: 4,
    date: "Sept 2010 - Nov 2010",
    iconsSrc: <IoBusinessOutline />,
    title: "Scope International (M) Sdn. Bhd. — Application Support Analyst",
    location: "Kuala Lumpur, Malaysia",
    description:
      "Oracle Siebel CRM on Demand Support Expert with experience in providing second-level support for the system in a Call Center environment, monitoring systems for issue prevention, resolving defects, performing deployments, and working with development teams to ensure successful project deployments. Expertise in providing support for this system has enabled me to deliver quality services and provide effective solutions to any challenges that arise.",
      achievement: "Achievement: Successfully resolved defects and performed deployments for the system, ensuring a high level of system reliability and availability.",
  },
  {
    id: 5,
    date: "Aug 2007 - Sep 2010",
    iconsSrc: <IoBusinessOutline />,
    title: "Astro — CRM and Billing Analyst",
    location: "Kuala Lumpur, Malaysia",
    description:
      "CRM System Support Expert with experience in providing third-level support for a CRM system covering Billing, Fulfillment, Inventory, Provisioning, and Ordering. Proven ability to deploy change management solutions, resolve defects, automate system tasks, and work with business and development teams to minimize the impact of changes. Expertise in CRM systems and change management solutions, as well as strong problem-solving and analytical skills.",
      achievement: "Achievement: My expertise in providing support for this system has enabled me to deliver quality services and provide effective solutions to any challenges that arise.",
  },
  {
    id: 6,
    date: "Apr 2005 - Jul 2007",
    iconsSrc: <IoBusinessOutline />,
    title: "CSA - Engineering Assistant",
    location: "Kuala Lumpur, Malaysia",
    description:
      "CRM System Support Expert with experience in providing second-level support for a CRM system covering Billing, Fulfillment, Inventory, Provisioning, and Ordering. Expertise in Lotus Notes software, as well as strong problem-solving and analytical skills.",
      achievement: "Achievement: Developed and delivered training to new support engineers on the CRM system and Lotus Notes software.",
  },
  {
    id: 7,
    date: "Mar 2001 - Jul 2001",
    iconsSrc: <IoBusinessOutline />,
    title: "G-AM Computer — Computer Technician",
    location: "Selangor, Malaysia",
    description:
      "Technical Support Expert with experience in setting up PCs and providing after-sales support with remarkable efficiency and attention to detail. Proven ability to offer comprehensive, reliable technical solutions and excellent customer service. Consistently goes above and beyond to ensure that clients have all the information they need to effectively utilize their systems. Dedicated to providing quality service that consistently exceeds expectations and earns a reputation of excellence.",
      achievement: "Achievement: Received a customer satisfaction rating of 95%, consistently exceeding the company's goal of 90%.",
  },
];

export const Education = [
  {
    id: 1,
    date: "May 2002 - June 2004",
    iconsSrc: <IoBriefcaseOutline />,
    title: "Campbell University — Bachelor's Degree in Computer Science",
    location: "Kuala Lumpur, Malaysia (twinning programme)",
    description:
      "Major in Mathematics",
  },
  {
    id: 2,
    date: "May 1999 - May 2002",
    iconsSrc: <IoBriefcaseOutline />,
    title: "TUNKU ABDUL RAHMAN COLLEGE — Diploma in Computer Science",
    location: "Kuala Lumpur, Malaysia",
    description:
      "Major in Mathematics",
  },
];

export const Projects = [
  {
    id: 1,
    name: "www.nfteaclub.xyz",
    imageSrc: img1,
    techs: "Discourse, Google Analytics, Search Console, Adsense, Cloud Computing, Cloudflare, Application hosting",
   
  },
  {
    id: 2,
    name: "www.Durianz.xyz",
    imageSrc: img2,
    techs: "React Js, Solidity, OpenZeppelin, Node JS art Generator",
    link: "#",
  },
  
];

export const SocialLinks = [


  {
    id: 1,
    iconSrc: (
      <FaXTwitter className="text-white text-3xl cursor-pointer" />
    ),
    name: "𝕏",
    link: "https://twitter.com/tech_lala",
  },
  {
    id: 2,
    iconSrc: (
      <IoLogoLinkedin className="text-blue-800 text-3xl cursor-pointer" />
    ),
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/lee-aun-chieng-1852a513a/",
  },
  {
    id: 3,
    iconSrc: (
      <IoLogoWhatsapp className="text-green-500 text-3xl cursor-pointer" />
    ),
    name: "Whatsapp",
    link: "https://wa.me/60122260821?text=YO, I want to know more about LAChieng",
  },
  {
    id: 4,
    iconSrc: (
      <AiOutlineMail className="text-red-500 text-3xl cursor-pointer" />
    ),
    name: "EmailMe",
    link: "mailto:cla732@gmail.com?subject=YO, I want to know more about LAChieng",
  },
];



